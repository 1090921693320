import React from "react";
import {siteMetaData} from "../../siteMetaData";
import {PageProps} from "gatsby";
import Faq from "../../components/recruiters-faq/Faq";

export default (props: PageProps) => {
  const language = "en"
  return (
    <Faq
      language={language}
      href={siteMetaData.getUrlForPath(props.location.pathname)} />
  )
}
